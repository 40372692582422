import React, {useEffect, useMemo} from "react";
import reportingApi from "../../services/api/reporting";
import {useAppSelector} from "../../store/hooks";
import {getReportingData, getReportingSearch} from "../../store/slice/reporting/reporting";
import {ReportingPeriodToString} from "../misc/DatePeriod";
import DatePicker from "../misc/DatePicker";
import CustomChart from "../misc/Chart";
import {sumReducer, uniqueReducer} from "../../assets/js/utils";
import {DailyLeadsConfig, DailyTotalLeadsConfig,} from "../../assets/js/charts/ChartJsConfig";
import {ChartConfiguration} from "chart.js";
import {Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {LoadingSkeleton} from "../utils/Blocks";

const Reporting = () => {
    const period = ReportingPeriodToString(useAppSelector(getReportingSearch));
    const [query, {isLoading, isFetching}] = reportingApi.useLazyGetReportingQuery();
    const reporting = useAppSelector(getReportingData);

    useEffect(()=>{
        query({
            period,
            last: Math.random(),
        })
    }, [JSON.stringify(period)])

    const config:ChartConfiguration<"line">|null = useMemo( () => {
        if (reporting){
            return DailyLeadsConfig(reporting)
        }

        return null;
    }, [reporting]);

    const configTotal:ChartConfiguration<"line">|null = useMemo( () => {
        if (reporting){
            return DailyTotalLeadsConfig(reporting)
        }

        return null;
    }, [reporting]);

    return <>
        <Grid container mt={2} p={2}>
            <Grid item xs={4}>
                <DatePicker id="reporting-datepicker" />
            </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={2} p={2}>
            <Grid item xs={12} md={6}>
                {
                    (isLoading || isFetching)
                    ? <Skeleton height={240} width={"100%"} sx={{transform: "none"}}/>
                    : configTotal && <CustomChart config={configTotal}/>
                }
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    (isLoading || isFetching)
                        ? <Skeleton height={240} width={"100%"} sx={{transform: "none"}}/>
                        : config && <CustomChart config={config}/>
                }
            </Grid>
            <Grid item xs={12}>
                {
                    (isLoading || isFetching)
                        ? <Skeleton height={110} width={"100%"} sx={{transform: "none"}}/>
                        : <ReportingTable />
                }
            </Grid>
        </Grid>
    </>
}

const ReportingTable = () => {
    const reporting = useAppSelector(getReportingData);

    const reportingTable = useMemo( ()=> {
        if (reporting){
            const uniqueForms = reporting.map(row => row.form).reduce(uniqueReducer, []);

            let products = uniqueForms.map(form => {
                const filteredData = reporting.filter(row => row.form === form);
                const customCode = filteredData.map(row => row.customCode).reduce(uniqueReducer, []);
                const res = {
                    form,
                    customCode: customCode.length > 0 ? customCode[0] : null,
                    optin: filteredData.filter(row => row.userApproval).map(row => row.nb).reduce(sumReducer, 0),
                };

                return res;
            })

            products.push({
                form: "Total",
                customCode: "",
                optin: products.map(row => row.optin).reduce(sumReducer, 0),
            })

            return products;
        }
        return null;
    }, [reporting]);

    return <TableContainer sx={{marginTop:2}}>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Opérations</TableCell>
                    <TableCell>Codes média</TableCell>
                    <TableCell>Leads OPT-IN</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    reportingTable && reportingTable
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(p => <TableRow key={p.form} hover>
                        <TableCell>{p.form}</TableCell>
                        <TableCell>{p.customCode}</TableCell>
                        <TableCell>{p.optin}</TableCell>
                    </TableRow>)
                }
            </TableBody>
        </Table>
    </TableContainer>
}

export default Reporting;