import {ReduxRtkApiResult} from "../../types";
import {FormattedMessage} from "react-intl";
import {ButtonProps, IconButton, Menu} from "@mui/material";
import React from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from "@mui/material/Button";

type DataBlockButtonProps = {
    data: ReduxRtkApiResult,
    children?: any,
    type?: "submit"|"button",
    // variant?: ButtonVariant,
    className?:string,
    onClick?:any,
    hideText?: boolean,
    disabled?: boolean,
};

export const LoadingButton:React.FC<DataBlockButtonProps> = (props) => {

    return <Button type={props.type} className={props.className} onClick={props.onClick} disabled={props.disabled}>
        {
            props.data.isLoading || props.data.isFetching
                ? <>
                    <span className="spinner-border spinner-border-sm"/>
                    {
                        !props.hideText && <span className="ms-1"><FormattedMessage id="pleaseWait" /></span>
                    }
                </>
                : props.children
        }
    </Button>
};

export const MoreActionsMenu = (props:ButtonProps) =>{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                {...props}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.children}
            </Menu>
        </div>
    )
}