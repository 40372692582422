import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import appReducer from "./slice/app";
import authApi from "../services/api/auth";
import {profileApi} from "../services/api/profile";
import {notificationReducer} from "./slice/notifications/notifications";
import {tabsReducer} from "./slice/tabs/tabs";
import {modalsReducer} from "./slice/modals/modals";
import {httpErrorMiddleware} from "./middlewares/errors";
import formsApi from "../services/api/forms";
import databasesApi from "../services/api/databases";
import registersApi from "../services/api/registers";
import {reportingReducer} from "./slice/reporting/reporting";

export const store = configureStore({
    reducer: {
        app: appReducer,
        notifications: notificationReducer,
        tabs: tabsReducer,
        reporting: reportingReducer,
        modals: modalsReducer,

        //RTK Api Reducers
        [authApi.reducerPath]: authApi.reducer,
        [profileApi.reducerPath] : profileApi.reducer,
        [formsApi.reducerPath] : formsApi.reducer,
        [databasesApi.reducerPath] : databasesApi.reducer,
        [registersApi.reducerPath] : registersApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
        httpErrorMiddleware,

        //RTK Api Middlewares
        authApi.middleware,
        profileApi.middleware,
        formsApi.middleware,
        databasesApi.middleware,
        registersApi.middleware,
    ])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
