import {useAppDispatch, useAppSelector} from "../../hooks";
import {useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {Notification, removeNotification} from "./notifications";
import {Alert, Snackbar} from "@mui/material";

const CustomSnack = (toast:Notification) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(removeNotification(toast.id));
        }, 5000);
    }, [true]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(removeNotification(toast.id));
        }, 5000);
        return () => clearTimeout(timer);
    }, [dispatch, toast.id]);

    const handleClose = () => {
        dispatch(removeNotification(toast.id));
    };

    return (
        <Snackbar open={true} autoHideDuration={5000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={toast.type === "success" ? "success" : "error"}
                variant="filled"
            >
                <div style={{ display: "flex" }}>
                    {
                        toast.toTranslate
                        ? (<FormattedMessage id={toast.message} />)
                        : (toast.message)
                    }
                </div>
            </Alert>
        </Snackbar>
    );
}

const AppSnackbar = () => {
    const snacks = useAppSelector(state => state.notifications);

    return (
        <>
            {snacks.map((t) => (
                <CustomSnack key={t.id} {...t} />
            ))}
        </>
    );
};

export default AppSnackbar;