import React, {useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getReportingSearch, reportingSetPeriod} from "../../store/slice/reporting/reporting";
import {DatePeriod, ReportingPeriodToString} from "./DatePeriod";
import {Litepicker} from "litepicker";
import "litepicker/dist/plugins/ranges";
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';

const DatePicker:React.FC<{id:string}> = (props) => {
    const dispatch = useAppDispatch();
    const period = useAppSelector(getReportingSearch);
    const intl = useIntl();

    const callback = () => {

        const customRanges = {
            [intl.formatMessage({id: "datepicker-today"})]: DatePeriod.Today,
            [intl.formatMessage({id: "datepicker-yesterday"})]: DatePeriod.Yesterday,
            [intl.formatMessage({id: "datepicker-this-week"})]: DatePeriod.ThisWeek,
            [intl.formatMessage({id: "datepicker-this-month"})]: DatePeriod.ThisMonth,
            [intl.formatMessage({id: "datepicker-previous-month"})]: DatePeriod.PreviousMonth,
            [intl.formatMessage({id: "datepicker-this-year"})]: DatePeriod.ThisYear,
        };

        new Litepicker({
            element: document.getElementById(props.id) as HTMLElement,
            plugins: ["ranges"],
            lang: "fr-FR",
            ranges: {
                customRanges,
            },
            setup: picker => {
                picker.on("selected", () => {
                    // @ts-ignore
                    const from = picker.getStartDate().toJSDate();
                    // @ts-ignore
                    const to = picker.getEndDate().toJSDate();

                    from.setHours(new Date().getHours());
                    to.setHours(new Date().getHours());

                    dispatch(reportingSetPeriod(ReportingPeriodToString([from, to])));
                });
            },
        })
    };

    useEffect(callback, [true]);

    return <div className="litepicker-btn">
        <DateRangeRoundedIcon/>
        <span id={props.id} className="p-1 fw-semibold cursor-pointer">
            <FormattedMessage id="datepicker-period-selected" values={{
                from: period[0].toLocaleDateString(),
                to: period[1].toLocaleDateString(),
            }} />
        </span>
        <span className="bi bi-caret-down ms-2">
        </span>
    </div>
};

export default DatePicker;
