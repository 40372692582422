import React from 'react';
import "./assets/css/custom.scss"

import { Routes } from "react-router-dom";
import { Route } from "react-router";
import Urls from "./assets/js/Urls";

import {IntlProvider} from "react-intl";
import fr from "./assets/js/dico/fr";

import AppRedirect from "./store/slice/AppRedirect";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import Register from "./components/auth/Register";

import DashboardHome from "./components/dashboard/DashboardHome";
import FormList from "./components/dashboard/forms/FormList";
import FormRegisterView from "./components/dashboard/leads/FormRegisterView";
import DatabaseLoader from "./components/dashboard/databases/DatabaseLoader";
import LeadList from "./components/dashboard/leads/LeadList";
import RefreshPassword from "./components/auth/RefreshPassword";

import {ThemeProvider, createTheme} from "@mui/material";
import UserVue from "./components/account/UserVue";

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#316adf',
            light: '#4687eb',
            dark: '#2747a6',
        },
        secondary: {
            main: '#505d79',
        },
        background: {
            default: '#f6f9ff',
        },
        text: {
            primary: '#211e2a',
            secondary: '#333947',
            disabled: '#394153',
        },
        error: {
            main: '#ff2300',
            light: '#ff3f0a',
            dark: '#cc1402',
        },
        success: {
            main: '#039855',
            light: '#21b76a',
            dark: '#027a48',
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
    },
});

function App() {
    return(<React.Fragment>
        <IntlProvider locale="fr" defaultLocale="fr" messages={fr}>
            <ThemeProvider theme={theme}>
                <AppRedirect/>
                <Routes>
                    {/*Auth*/}
                    <Route path={Urls.login} element={<Login />}/>
                    <Route path={Urls.register} element={<Register />}/>
                    <Route path={Urls.forgot} element={<ForgotPassword />}/>
                    <Route path={Urls.passwordReinit(":token")} element={<RefreshPassword />}/>

                    {/*Dashboard*/}
                    <Route path={Urls.home} element={<DashboardHome />}/>
                    <Route path={Urls.forms} element={<FormList />}/>
                    <Route path={Urls.formRegisters} element={<FormRegisterView />}/>
                    <Route path={Urls.databasesLoader(":database")} element={<DatabaseLoader />}/>
                    <Route path={Urls.leads} element={<LeadList />}/>

                    {/*Account*/}
                    <Route path={Urls.me} element={<UserVue />}/>
                </Routes>
            </ThemeProvider>
        </IntlProvider>
    </React.Fragment>)
}

export default App;
