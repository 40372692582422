import React, {useEffect, useState} from "react";
import AppSecured from "../../../AppSecured";
import {AppWrapper, LoadingSkeleton} from "../../utils/Blocks";
import formsApi from "../../../services/api/forms";
import registersApi from "../../../services/api/registers";
import {useAppDispatch} from "../../../store/hooks";
import {
    Box,
    Card, CardContent,
    Chip,
    FormControl,
    Grid,
    InputLabel, List, ListItem, ListItemIcon, ListItemText,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const toMultiline = (row:any) => <>
    {
        row.split("\n").map((part:any) => <p key={Math.random()}>{part}&nbsp;</p>)
    }
</>

const Badge:React.FC<{type:string}> = ({type= "success",children}) => <span className={`badge badge-outline-${type} mt-1 mb-3 align-self-start`}>
    {children}
</span>

const FormRegisterView = () => {

    const forms = formsApi.useGetFormsQuery();
    const [currentRegister, currentRegisterResult] = registersApi.useLazyGetRegisterQuery();
    const [selectedRegister, setSelectedRegister] = React.useState('');

    return <AppSecured>
        <AppWrapper>
            <Paper elevation={0}>
                <Box sx={{padding:2}}>
                    <Typography className={"display-xs"}>Registre RGPD</Typography>
                </Box>
                <Box sx={{padding:2, borderTop: `1px solid #d5d9e2`}}>
                    <LoadingSkeleton
                        data={forms}
                        height={56}
                        sx={{transform: "none"}}
                    >
                        <FormControl fullWidth>
                            <InputLabel id="registers-select-label">Opération</InputLabel>
                            <Select
                                labelId="registers-select-label"
                                id="registers-select"
                                value={selectedRegister}
                                label="Opération"
                                onChange={e => {
                                    setSelectedRegister(e.target.value as string);
                                    currentRegister(e.target.value as string)
                                }
                                }>
                                <MenuItem disabled={true} selected={true}>Sélectionnez une opération</MenuItem>
                                {
                                    forms.data && forms.data.map((f:any) => <MenuItem key={f.form.form_register.id} value={f.form.form_register.id || ""}>{f.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </LoadingSkeleton>
                </Box>
                {
                    selectedRegister && <Grid container aria-multiline rowSpacing={2} columnSpacing={2} p={2}>
                        <LoadingSkeleton
                            data={currentRegisterResult}
                            height={28}
                            width={"100%"}
                            sx={{marginLeft: 2, transform: "none"}}
                        >
                            {currentRegisterResult.data && <Grid item sm={12}>
                                <Typography className={"text-lg"}>Fiche crée le : {new Date(currentRegisterResult.data?.timestamps?.created * 1000).toLocaleString('fr-FR', {year: 'numeric', month: 'long', day:'numeric'})}</Typography>
                            </Grid>}
                        </LoadingSkeleton>
                        <Grid item sm={6}>
                            <LoadingSkeleton
                                data={currentRegisterResult}
                                height={258}
                                sx={{transform: "none"}}
                            >
                                {currentRegisterResult.data && <Card variant={"outlined"}>
                                    <Grid container sx={{backgroundColor:"#4687eb", color: "white", height: "100%"}} p={2} rowSpacing={1}>
                                        <Grid item sm={6}><Typography className={"text-md bold"}>Dernière modification</Typography></Grid>
                                        <Grid item sm={6}>{new Date(currentRegisterResult.data?.timestamps?.updated * 1000).toLocaleString('fr-FR', {year: 'numeric', month: 'long', day:'numeric'})}</Grid>
                                        <Grid item sm={6}><Typography className={"text-md bold"}>Objectifs poursuivis</Typography></Grid>
                                        <Grid item sm={6}>{currentRegisterResult.data.objectives}</Grid>
                                        <Grid item sm={6}><Typography className={"text-md bold"}>Hébergement</Typography></Grid>
                                        <Grid item sm={6}>AWS</Grid>
                                        <Grid item sm={6}><Typography className={"text-md bold"}>Personnes concernées par la collecte</Typography></Grid>
                                        <Grid item sm={6}>{currentRegisterResult.data.target}</Grid>
                                        <Grid item sm={6}><Typography className={"text-md bold"}>Pays de transfer des données (hors UE)</Typography></Grid>
                                        <Grid item sm={6}>{currentRegisterResult.data.transfert_countries}</Grid>
                                        <Grid item sm={6}><Typography className={"text-md bold"}>Responsable de traitement</Typography></Grid>
                                        <Grid item sm={6}>ORIXA MEDIA . Il collecte la donnée ; la compile et la traite pour le compte des clients en tant que sous-traitant. Orixa Media agit en tant que sous traitant.</Grid>
                                    </Grid>
                                </Card>}
                            </LoadingSkeleton>
                        </Grid>
                        <Grid item sm={6}>
                            <Grid container sx={{height:"100%"}} rowSpacing={2} columnSpacing={2}>
                                <Grid item sm={6}>
                                    <LoadingSkeleton
                                        data={currentRegisterResult}
                                        height={130}
                                        sx={{transform: "none"}}
                                    >
                                        {currentRegisterResult.data && <Card variant={"outlined"} sx={{height:"100%"}}>
                                            <CardContent>
                                                <Typography className={"text-md bold"} sx={{marginBottom: 1}}>Sous traitant ultérieur</Typography>
                                                {toMultiline(currentRegisterResult.data.subcontractors)}
                                            </CardContent>
                                        </Card>}
                                    </LoadingSkeleton>
                                </Grid>
                                <Grid item sm={6}>
                                    <LoadingSkeleton
                                        data={currentRegisterResult}
                                        height={130}
                                        sx={{transform: "none"}}
                                    >
                                        {currentRegisterResult.data && <Card variant={"outlined"} sx={{height:"100%"}}>
                                            <CardContent>
                                                <Typography className={"text-md bold"} sx={{marginBottom: 1}}>Durée de rétention</Typography>
                                                <Chip variant={"outlined"} size={"small"} color={"success"} label={`${currentRegisterResult.data.retention_month} mois`}/>
                                            </CardContent>
                                        </Card>}
                                    </LoadingSkeleton>
                                </Grid>
                                <Grid item sm={6}>
                                    <LoadingSkeleton
                                        data={currentRegisterResult}
                                        height={130}
                                        sx={{transform: "none"}}
                                    >
                                        {currentRegisterResult.data && <Card variant={"outlined"} sx={{height:"100%"}}>
                                            <CardContent>
                                                <Typography className={"text-md bold"} sx={{marginBottom: 1}}>Liste de champs collectés</Typography>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    gap: .5
                                                }}>
                                                    <Chip variant={"outlined"} size={"small"} color={"success"} label={"email"}/>
                                                    {
                                                        currentRegisterResult.data.form.landing.fields
                                                            .filter((f:any) => f.code != "email")
                                                            .map((f:any) => <Chip
                                                            key={f.code}
                                                            color={f.mandatory ? "success" : "secondary"}
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            label={f.code}
                                                        /> )
                                                    }
                                                </Box>
                                            </CardContent>
                                        </Card>}
                                    </LoadingSkeleton>
                                </Grid>
                                <Grid item sm={6}>
                                    <LoadingSkeleton
                                        data={currentRegisterResult}
                                        height={130}
                                        sx={{transform: "none"}}
                                    >
                                        {currentRegisterResult.data && <Card variant={"outlined"} sx={{height:"100%"}}>
                                            <CardContent>
                                                <Typography className={"text-md bold"} sx={{marginBottom: 1}}>Données sensibles</Typography>
                                                <Chip variant={"outlined"} size={"small"} color={!currentRegisterResult.data.is_sensitive_data ? 'success' : 'error'} label={currentRegisterResult.data.is_sensitive_data ? 'Oui' : 'Non'}/>
                                                {
                                                    currentRegisterResult.data.is_sensitive_data && <>
                                                        <strong className="fs-12 d-block mt-3"> Type de données sensibles </strong>
                                                        <p className="my-1 align-self-start text-danger mb-0">{toMultiline(currentRegisterResult.data.sensitive_data_detail)}</p>
                                                    </>
                                                }
                                            </CardContent>
                                        </Card>}
                                    </LoadingSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <LoadingSkeleton
                                data={currentRegisterResult}
                                height={150}
                                sx={{marginBottom:2, transform: "none"}}
                            >
                                {currentRegisterResult.data && <Card variant={"outlined"} sx={{marginBottom:2}}>
                                    <CardContent>
                                        <Typography className={"text-md bold"}>Mesure de traçabilité</Typography>
                                        <List dense>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Log des accès utilisateurs ou webservice sur la plateforme.</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Récupération de la date, de l'IP.</ListItemText></ListItem>
                                        </List>
                                    </CardContent>
                                </Card>}
                            </LoadingSkeleton>
                            <LoadingSkeleton
                                data={currentRegisterResult}
                                height={150}
                                sx={{marginBottom:2, transform: "none"}}
                            >
                                {currentRegisterResult.data && <Card variant={"outlined"} sx={{marginBottom:2}}>
                                    <CardContent>
                                        <Typography className={"text-md bold"}>Backup des données</Typography>
                                        <List dense>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Backup des données 3 fois par jour sur un serveur de backup sécurisé via rsync.</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Serveurs de backup et bases de données inaccessibles de l'extérieur.</ListItemText></ListItem>
                                        </List>
                                    </CardContent>
                                </Card>}
                            </LoadingSkeleton>
                            <LoadingSkeleton
                                data={currentRegisterResult}
                                height={240}
                                sx={{transform: "none"}}
                            >
                                {currentRegisterResult.data && <Card variant={"outlined"}>
                                    <CardContent>
                                        <Typography className={"text-md bold"}>Base légale</Typography>
                                        <List dense>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Consentement (lead optin) / Co-responsabilité avec facebook.</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>"Traitement des données : Anonymisation des données sur l'interface administrateur Orixa Media."</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>"Données concernées : Les données collectées + base de données clients."</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Durée de conservation des données 12 mois maximum.</ListItemText></ListItem>
                                        </List>
                                    </CardContent>
                                </Card>}
                            </LoadingSkeleton>
                        </Grid>
                        <Grid item sm={6}>
                            <LoadingSkeleton
                                data={currentRegisterResult}
                                height={585}
                                sx={{transform: "none"}}
                            >
                                {currentRegisterResult.data && <Card variant={"outlined"} sx={{height:"100%"}}>
                                    <CardContent>
                                        <Typography className={"text-md bold"}>Cryptage des données</Typography>
                                        <List dense>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Données téléchargeables via l'interface client via HTTPS.</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Seul l'e-mail est visible sur la plateforme, et est de plus anonymisé pour les accès admin (hors accès client).</ListItemText></ListItem>
                                            <ListItem disableGutters><ListItemIcon><ArrowRightIcon/></ListItemIcon><ListItemText>Pour les éventuels sous-traitants, les données sont envoyées par webservice uniquement si celui-ci est sécurisé (HTTPS + clé pour authentification).</ListItemText></ListItem>
                                        </List>
                                    </CardContent>
                                </Card>}
                            </LoadingSkeleton>
                        </Grid>
                    </Grid>
                }
            </Paper>
        </AppWrapper>
    </AppSecured>
}

export default FormRegisterView;