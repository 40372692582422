import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Database, ModalTypes} from "../../../types";
import {AppThunk, RootState} from "../../store";

type ModalData = {
    type: ModalTypes,
    data?: any,
    tab?:string,
}

const initialState:Array<ModalData> = [];

const modals = createSlice({
    name: "modals",
    initialState,
    reducers: {
        modalOpen: (state, action:PayloadAction<ModalData>) => {
            state.length === 0 && state.push(action.payload);
        },
        modalClose: (state) => {
            state.pop();
        },
    },
});

export const modalsReducer = modals.reducer;
export const {modalOpen, modalClose} = modals.actions;

export const openModalDatabasePurge = (props:Database):AppThunk => dispatch => {
    dispatch(modals.actions.modalOpen({type: ModalTypes.DatabasePurge, data:props,}));
};

export const isModalOpened = (type: ModalTypes) => (state: RootState): boolean => state.modals.length > 0
    ? type === state.modals[state.modals.length - 1].type
    : false

export const getModalData = (state: RootState): any | undefined => state.modals.length > 0
    ? state.modals[state.modals.length - 1].data
    : null