import React from "react";
import {ReduxRtkApiResult} from "../../types";
import {CustomAlert} from "./CustomAlerts";
import {Skeleton, SkeletonProps} from "@mui/material";

type DataBlockProps = {
    data: ReduxRtkApiResult,
    children: any,
}

export const BlockError:React.FC<Pick<DataBlockProps, "data">> = ({data, children}) => {
    const status = data?.error?.originalStatus || data?.error?.status;

    return <>
        {
            data.isError && <CustomAlert className="my-3" variant="danger">
                {
                    "401" === status.toString()
                        ? "Utilisateur inconnu"
                        : "403" === status.toString()
                            ? "Accès à une ressource non autorisé"
                            : "404" === status.toString()
                                ? "Ressource introuvable"
                                : "500" === status.toString()
                                    ? data?.error?.data?.error || "Erreur serveur"
                                    : `Erreur inconnue (${status})`
                }
            </CustomAlert>
        }
    </>;
};

export const BlockLoadingOnNull:React.FC<{data:any}> = ({data,children}) =>{
    return <>
        {
            null === data && <div className="p-4 text-center">
        <span className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
        </span>
            </div>
        }
        {
            data && children
        }
    </>
}
const BlockLoading:React.FC<DataBlockProps> = ({data,children}) =>{
    return <>
        {
            data.isLoading || data.isFetching
                ? <div className="text-center mt-4">
                    <span className="spinner-grow" />
                </div>
                : data.isSuccess
                    ? children
                    : null
        }
    </>
}

// /deprecated
export default BlockLoading;

type BlockConditionProps = {
    condition: boolean,
}

export const BlockIfTrue:React.FC<BlockConditionProps> = ({condition, children}) => <>
    {
        condition && children
    }
</>;

export const AppWrapper:React.FC = ({children}) => <div className="app-wrapper">{children}</div>
export const Box:React.FC = ({children}) => <div className="box">{children}</div>
export const BoxHead:React.FC = ({children}) => <div className="box-head">{children}</div>
export const BoxTitle:React.FC = ({children}) => <p className="fs-16 fw-bold m-0">{children}</p>
export const BoxBody:React.FC = ({children}) => <div className="box-body">{children}</div>
export const BoxFooter:React.FC = ({children}) => <div className="box-footer">{children}</div>


//New Blocks
export const LoadingSkeleton = ({ data, children, ...skeletonProps }:DataBlockProps & SkeletonProps) => {
    return <>
        {
            data.isLoading || data.isFetching
                ? <Skeleton {...skeletonProps} />
                : data.isSuccess
                    ? children
                    : null
        }
    </>
};