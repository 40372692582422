import {ReportingPeriod, ReportingPeriodString} from "../../store/slice/reporting/reporting";

const addDays = (date:Date, days: number):Date => {
    const dateOffset = new Date();
    dateOffset.setTime(date.getTime() + days * 86400 * 1000);

    return dateOffset;
}

const today = ():ReportingPeriod => ([new Date(), new Date()]);

const yesterday = ():ReportingPeriod => ([addDays(new Date(), -1), addDays(new Date(), -1)]);

const thisMonth = ():ReportingPeriod => {
    const from = new Date(), to = new Date();
    from.setDate(1);

    return [from, to];
};

const previousMonth = ():ReportingPeriod => {
    const period:ReportingPeriod = thisMonth();

    // Sub 1 day to go to previous month last day
    period[0] = addDays(period[0], -1);
    period[1] = addDays(period[1], -1);

    // Go to beginning of previous month
    period[0].setDate(1);

    return period;
}

const thisYear = ():ReportingPeriod => {
    const period = today();
    period[0] = new Date(`${new Date().getFullYear()}-01-01`);

    return period;
}

const thisWeek = () => {
    const period = today();

    const MONDAY = 1;
    const SUNDAY = 0;

    const startOfWeek:number = MONDAY;

    period[0] = addDays(period[0], -1 * (period[0].getDay() - startOfWeek))

    return period;
}

type Periods = "Today"|"Yesterday"|"ThisYear"|"ThisMonth"|"PreviousMonth"|"ThisWeek"

export const DatePeriod:Record<Periods, ReportingPeriod> = {
    Today: today(),
    Yesterday: yesterday(),
    ThisYear: thisYear(),
    ThisMonth: thisMonth(),
    PreviousMonth: previousMonth(),
    ThisWeek: thisWeek(),
};

export const ReportingPeriodToString = (period:ReportingPeriod):ReportingPeriodString => ([
    period[0].toISOString().substring(0, 10),
    period[1].toISOString().substring(0, 10),
])

export const ReportinPeriodFromString = (period: ReportingPeriodString):ReportingPeriod => ([
    new Date(period[0]),
    new Date(period[1]),
])