import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {DatePeriod, ReportingPeriodToString, ReportinPeriodFromString} from "../../../components/misc/DatePeriod";
import reportingApi from "../../../services/api/reporting";
import {ReportingEntry} from "../../../types";

export type ReportingPeriod = [Date, Date];
export type ReportingPeriodString = [string, string];

export type Reporting = Array<ReportingEntry>;

export type Kpi = "nb";

type ReportingState = {
    search: ReportingPeriodString,
    reporting: Reporting|null,
};

const initialState: ReportingState = {
    search: ReportingPeriodToString(DatePeriod.ThisWeek),
    reporting: null,
};

const reporting = createSlice({
    name: "reporting",
    initialState,
    reducers: {
        setPeriod: (state, action: PayloadAction<ReportingPeriodString>) => {
            state.search = action.payload;
        },
    },
    extraReducers: builder => {
        builder
        .addMatcher(reportingApi.endpoints.getReporting.matchFulfilled, (state, action) => {
            state.reporting = action.payload;
        })
    }
});

export const reportingReducer = reporting.reducer;

export const getReportingSearch = (state:RootState):ReportingPeriod => ReportinPeriodFromString(state.reporting.search);
export const getReportingData = (state:RootState):Reporting|null => state.reporting.reporting;

export const reportingSetPeriod = reporting.actions.setPeriod;
