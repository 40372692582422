import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {profileApi} from "../../../services/api/profile";
import databasesApi from "../../../services/api/databases";

type NewNotification = {
    type: "success"|"danger",
    message: string,
    toTranslate?:boolean,
}

export type Notification = NewNotification & {
    id: number,
};

type NotificationState = Array<Notification>

const initialState: NotificationState = [];

const NewSuccessNotification = (message:string):Notification => ({
    id: Math.random(),
    type: "success",
    message,
    toTranslate: true,
});

const notifications = createSlice({
    name: "notifications",
    reducers: {
        addNotification: (state, action: PayloadAction<NewNotification>) => {
            state.push({
                id: Math.random(),
                type: action.payload.type,
                message: action.payload.message,
                toTranslate: action.payload.toTranslate || false,
            });
        },
        removeNotification: (state, action: PayloadAction<number>) => {
            return state.filter((n:Notification) => n.id !== action.payload);
        },
        clearNotifications: (state) => {
            return [];
        }
    },
    initialState,
    extraReducers: builder => {
        builder
            .addMatcher(profileApi.endpoints.passwordChange.matchFulfilled, (state, action:PayloadAction<NewNotification>) => {
                state.push(NewSuccessNotification("updatePasswordSuccess"));
            })
            .addMatcher(profileApi.endpoints.changeInformations.matchFulfilled, (state, action:PayloadAction<NewNotification>) => {
                state.push(NewSuccessNotification("updateInformationsSuccess"));
            })
            .addMatcher(databasesApi.endpoints.deleteDatabase.matchFulfilled, (state) =>{
                state.push(NewSuccessNotification("deleteDatabaseSuccess"));
            })
            .addMatcher(databasesApi.endpoints.databaseUpload.matchFulfilled, (state) =>{
                state.push(NewSuccessNotification("databaseUploadSuccess"));
            })
        ;
    }
});

export const {
    addNotification,
    removeNotification,
    clearNotifications,
} = notifications.actions;

export const notificationReducer = notifications.reducer;