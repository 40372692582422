export const sumReducer = (prev:number, row:number):number => prev + row;

export const safeDivider = (num:number, den:number):number => den > 0 ? num / den : 0;

export const uniqueReducer = (prev:Array<any>, current:any):Array<any> => {
    if (-1 === prev.indexOf(current)) {
        prev.push(current);
    }

    return prev;
};

export const LocaleToCountry = (locale:string):string => locale.split("_")[0].toUpperCase();

export const addDatalayerEvent = (event: string) => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
        event,
    });
}
