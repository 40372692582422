import React from "react";
import {Alert} from "@mui/material";

type customAlertsProps = {
    variant: "success"|"danger"|"info",
    className?: string,
}

export const CustomAlert:React.FC<customAlertsProps> = ({variant, className,children}) =>{
    const alertVariant = variant === 'danger' ? 'error' : variant;

    return <Alert severity={alertVariant}>
        {
            children && <div className="d-flex flex-fill justify-content-between align-items-center">{children}</div>
        }
    </Alert>
}