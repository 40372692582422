import AppSecured from "../../../AppSecured";
import {AppWrapper, LoadingSkeleton} from "../../utils/Blocks";
import formsApi from "../../../services/api/forms";
import {Link as RouterLink} from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip, Link,
} from "@mui/material";

const FormList = () => {
    const forms = formsApi.useGetFormsQuery();

    return <AppSecured>
        <AppWrapper>
            <Paper elevation={0} sx={{padding:2}}>
                <Box mb={2}>
                    <Typography className={"display-xs"}>Mes opérations</Typography>
                </Box>
                <LoadingSkeleton data={forms} height={500} sx={{transform:"none"}}>
                    <TableContainer >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Opérations</TableCell>
                                    <TableCell>Base E-mails</TableCell>
                                    <TableCell>Landing page</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    forms.data && forms.data.map((row:any) => <TableRow key={row.id} hover>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>
                                            {console.log(row)}
                                            {
                                                row.landing_option?.email_database && <Link href={Urls.databasesLoader(row.landing_option.email_database.access_token)}>{row.landing_option?.email_database.name}</Link>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                row.enabled_templates?.map((tpl:any, index:any) => <Chip
                                                    key={index}
                                                    component="a"
                                                    label={`V${index + 1}`}
                                                    href={Urls.front.template(row.site.domain, row.code, tpl)}
                                                    sx={{marginRight:1}}
                                                    target="_blank"
                                                    size={"small"}
                                                    variant={"outlined"}
                                                    clickable
                                                />)
                                            }
                                        </TableCell>
                                    </TableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </LoadingSkeleton>
            </Paper>
        </AppWrapper>
    </AppSecured>
}

export default FormList;