import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import ApiUrls, {getCustomHeaders} from "../../assets/js/ApiUrls";
import {CustomForm, Country} from "../../types";

export type customerFormResponse = {
    forms: Array<{form: CustomForm}>
}

const formsApi = createApi({
    reducerPath: "api.forms",
    baseQuery: fetchBaseQuery({
        prepareHeaders:(headers, store) => getCustomHeaders(headers, true),
        baseUrl: process.env.REACT_APP_API_DOMAIN,
    }),
    tagTypes:[],
    endpoints: (build) => ({
        getForms: build.query<any, void>({
            query: () => ApiUrls.landings.list,
        }),
        getRegisters: build.query<any, void>({
            query: () => ApiUrls.registers.list,
        }),
        getCountries: build.query<Array<Country>, void>({
            query: () => ApiUrls.list.countries,
       })
    })
});

export default formsApi;