import React from "react";

export type SimpleFormData = Record<string, any>;

type HTMLAllFormTypes = HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement;

type SimpleFormType = {
    children: any,
    onSubmit: (data:SimpleFormData) => void,
    className?: string,
};

/**
 * Build a json object from dotted name
 * Ex:
 *      customer.address.country = "FR"  => {customer:address:{country: "FR"}}
 * @param name
 * @param value
 * @param resultObject
 * @constructor
 */
export const JsonBuilder = (name:string, value: any, resultObject:SimpleFormData):SimpleFormData => {
    name = KeySanitizer(name);
    const parts:Array<string> = name.split(".");
    let currentKey:string = parts[0];

    if (parts.length > 1) {
        const nextKey:number = parseInt(parts[1]);

        if (!isNaN(nextKey)) {
            // If next key is numeric, current key is array
            resultObject[currentKey] = resultObject[currentKey] || [];

            if (parts.length >= 3) {
                // If 2nd next key exists, array is array of object
                resultObject[currentKey][nextKey] = resultObject[currentKey][nextKey] || {};
                resultObject[currentKey][nextKey] = JsonBuilder(parts.slice(2).join("."), value, resultObject[currentKey][nextKey]);
            } else {
                // Else, just a simple array
                resultObject[currentKey][nextKey] = value;
            }
        } else {
            resultObject[currentKey] = JsonBuilder(parts.slice(1).join("."), value, resultObject[currentKey] || {});
        }
    } else if (1 === parts.length) {
        if (IsArrayKey(currentKey)) {
            currentKey = currentKey.replace("[", "").replace("]", "");
            resultObject[currentKey] = resultObject[currentKey] || [];
            if (null !== value) {
                resultObject[currentKey].push(value);
            }
        } else {
            resultObject[currentKey] = value;
        }
    }

    return resultObject;
};

/**
 * Replace bracket notation with dotted notation
 * Keep only brackets for array notation
 * Ex:
 *      customer[age] => customer.age
 *      customer[roles][] => customer.roles[]
 */
export const KeySanitizer = (key:string):string => {
    return key
        // Replace [ with . if followed by alphanums
        .replace(/\[(\w+)/g, ".$1")
        // Remove ] with . if preceded by alphanums
        .replace(/(\w+)\]/g, "$1")
        // Remove ending .
        .replace(/\.$/, "")
        ;
};

/**
 * Check if key is array notation (name ending with [])
 */
export const IsArrayKey = (key:string):boolean => {
    const sanitized = KeySanitizer(key);

    return !!sanitized.match(/\[\]$/);
}

const Converter: Record<string, (element:HTMLAllFormTypes) => any> = {
    // Return data as number for <input type="number" />
    "number": (element:HTMLAllFormTypes) => element.value.length > 0 ? parseFloat(element.value) : null,
    // Return array of selected values for <select multiple>
    "select-multiple": (element:HTMLAllFormTypes) => {
        const select = element as HTMLSelectElement;

        return Array.from(select.options).filter(o => o.selected).map(o => o.value);
    },
    // Returns boolean as value if checked or input value if array name and checked (Ex: roles[])
    "checkbox": (element:HTMLAllFormTypes) => {
        const chk = element as HTMLInputElement;

        if (IsArrayKey(chk.name)) {
            if (chk.checked) {
                return chk.value;
            } else {
                return null;
            }
        }

        return chk.checked;
    },
    // Return raw values for everything else ( <input type="text|hidden|date..." />, <textarea />, ...)
    "default": (element:HTMLAllFormTypes) => element.value,
};

const SimpleForm:React.FC<SimpleFormType> = (props) => {
    return <form onSubmit={event => {
        event.preventDefault();

        let data:SimpleFormData = {};

        ["input", "select", "textarea"].forEach(tag => {
            const tags:Array<HTMLAllFormTypes> = Array.from(event.currentTarget.getElementsByTagName(tag)) as Array<HTMLAllFormTypes>;

            tags
                .filter((tag:HTMLAllFormTypes) => {
                    return tag.name.length > 0;
                })
                .filter(tag => {
                    tag = tag as HTMLInputElement;

                    return "radio" !== tag.type || tag.checked;
                }).forEach(tag => {
                const converter = Converter[tag.type] || Converter.default;

                data = JsonBuilder(tag.name, converter(tag), data);
            });
        });

        props.onSubmit(data);
    }} className={props.className}>{props.children}</form>
};

export default SimpleForm;