import React, {useEffect, useState} from "react";
import {Database, ModalTypes} from "../../../../types";
import databasesApi from "../../../../services/api/databases";
import {Box, Modal, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {getModalData, isModalOpened, modalClose} from "../modals";
import {CustomAlert} from "../../../../components/utils/CustomAlerts";
import Button from "@mui/material/Button";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: .5,
    boxShadow: 24,
    p: 4,
};

export default function ModalDatabasePurge() {
    const dispatch = useAppDispatch();
    const show = useAppSelector(isModalOpened(ModalTypes.DatabasePurge));
    const data = useAppSelector<Database>(getModalData);

    const close = () => dispatch(modalClose());
    const [databaseDelete, databaseDeleteResult] = databasesApi.useDeleteDatabaseMutation();

    const [database, setDatabase] = useState<Database>();

    useEffect(() => {
        if (show && data) {
            setDatabase(data)
        } else {
            setDatabase({
                access_token: "",
                forms: undefined,
                histories: [],
                id: 0,
                name: "",
                nb_emails: 0,
                nb_phones: 0
            })
        }
    },[show, data])

    useEffect(() => {
        if(databaseDeleteResult.isSuccess){
            close();
        }
    },[databaseDeleteResult])

    return <Modal
            open={show}
            onClose={close}
        >
        <Box sx={style}>
            <Typography className={"display-xs"}>Suppression de la base de données</Typography>
            <Typography sx={{ mt: 2 }}>
                La base de données <span className="text-primary fw-bolder">{database?.name}</span> et ses e-mails seront effacés.<br/><br/>Êtes vous sur de bien vouloir supprimer la base ?
            </Typography>
            {
                database?.forms && database?.forms.length > 0 && <CustomAlert variant="danger">
                    <div>
                        <p className="fw-bolder">Attention !</p>
                        <p>Les opérations suivantes devront être rattachées à une autre base de données pour la gestion des doublons : </p>
                        <ul className="mb-0">
                            {
                                database?.forms.map(f => <li key={f.name}>{f.name}</li>)
                            }
                        </ul>
                    </div>
                </CustomAlert>
            }
            <Box sx={{marginTop: 3, textAlign: "right"}}>
                <Button onClick={close} sx={{marginRight:2}}>Annuler</Button>
                {database && <Button color="error" variant={"contained"} onClick={() => {databaseDelete(database.id)}}>Supprimer la base de données</Button>}
            </Box>
        </Box>
    </Modal>;
}