import React from "react";
import AppSecured from "../../AppSecured";
import {AppWrapper} from "../utils/Blocks";
import {Box, Grid, Paper, TextField, Typography} from "@mui/material";
import {profileApi} from "../../services/api/profile";
import SimpleForm from "../misc/SimpleForm";
import {PasswordUpdateRequest, userInfosUpdateRequest} from "../../types";
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";

const UserVue = () => {

    return <AppSecured>
        <AppWrapper>
            <Paper elevation={0} sx={{padding:2}}>
                <Box mb={2}>
                    <Typography className={"display-xs"}>Mon compte</Typography>
                </Box>
                <Grid container>
                    <Grid item xs={6} sx={{borderRight: "1px solid #ccc"}}>
                        <AccountEditInfo/>
                    </Grid>
                    <Grid item xs={6}>
                        <AccountEditPassword/>
                    </Grid>
                </Grid>
            </Paper>
        </AppWrapper>
    </AppSecured>
}

export default UserVue;

const AccountEditInfo = () => {

    const infos = profileApi.useGetInformationsQuery();
    const [infosEdit, infosEditResult] = profileApi.useChangeInformationsMutation();

    console.log(infos);

    return <Box sx={{paddingRight:2}}>
        <Typography className={"text-lg"}>Informations personnelles</Typography>

        <SimpleForm onSubmit={data => {
            infosEdit(data as userInfosUpdateRequest);
        }}>
            {
                infos.isSuccess && <>
                    <Typography className={"text-sm"} sx={{marginTop: 1}}>Email<br/>{infos?.data?.login.email}</Typography>
                    <TextField label={"Nom"} size={"small"} type={"text"} name={"lastname"} fullWidth sx={{marginTop: 2}} defaultValue={infos?.data?.last_name || ""}/>
                    <TextField label={"Prénom"} size={"small"} type={"text"} name={"firstname"} fullWidth sx={{marginTop: 2}} defaultValue={infos?.data?.first_name || ""}/>
                </>
            }
            <Button color={"primary"} variant={"contained"} type="submit" sx={{marginTop: 2, display: "block", marginLeft: "auto"}}>
                <FormattedMessage id="validate" />
            </Button>
        </SimpleForm>
    </Box>
}

const AccountEditPassword = () => {

    const [passwordUpdate, passwordUpdateResult] = profileApi.usePasswordChangeMutation();

    return <Box sx={{paddingLeft:2}}>
        <Typography className={"text-lg"}>Mot de passe</Typography>
        <Typography className={"text-sm"} sx={{marginTop: 1}}>Le mot de passe de votre compte protège l’accès à votre dashboard.<br/>Pour des raisons de sécurité, il est important de le modifier régulièrement.</Typography>
        <SimpleForm onSubmit={data => {
            passwordUpdate(data as PasswordUpdateRequest);
        }}>
            <TextField label={"Nouveau mot de passe"} size={"small"} type={"password"} name={"password"} fullWidth sx={{marginTop: 2}} required={true}/>
            <TextField label={"Confirmer mot de passe"} size={"small"} type={"password"} name={"confirmation"} fullWidth sx={{marginTop: 2}} required={true}/>
            <Button color={"primary"} variant={"contained"} type="submit" sx={{marginTop: 2, display: "block", marginLeft: "auto"}}>
                <FormattedMessage id="validate" />
            </Button>
        </SimpleForm>
    </Box>
}