import {useNavigate, useParams} from "react-router-dom";
import AppSecured from "../../../AppSecured";
import BlockLoading, {AppWrapper, Box, BoxBody, BoxFooter, BoxHead, BoxTitle} from "../../utils/Blocks";
import databasesApi from "../../../services/api/databases";
import React, {FormEvent} from "react";
import {PrimaryCard, SimpleCard} from "../../utils/Cards";
import Urls from "../../../assets/js/Urls";
import {CustomTable} from "../../misc/Table";
import moment from "moment";
import {DatabaseEditRequest} from "../../../types";

const Uploader:React.FC<{id:number}> = ({id}) => {
    const [databaseEdit, databaseEditResult] = databasesApi.useDatabaseUploadMutation();

    return<>
        {/*<Form onSubmit={(e:FormEvent<HTMLFormElement>) => {*/}
        {/*    e.preventDefault();*/}
        {/*    const formData:DatabaseEditRequest = {*/}
        {/*        body: new FormData(e.currentTarget),*/}
        {/*        database_id: id,*/}
        {/*    }*/}
        {/*    databaseEdit(formData);*/}
        {/*}}>*/}
        {/*    <Form.Label>Mettre à jour la base email</Form.Label>*/}
        {/*    <InputGroup className="mb-3">*/}
        {/*        <Form.Control*/}
        {/*            className="border"*/}
        {/*            type="file"*/}
        {/*            accept=".csv, .txt"*/}
        {/*            name="database-file"*/}
        {/*        />*/}
        {/*        <Button type="submit" variant="primary">Envoyer</Button>*/}
        {/*    </InputGroup>*/}
        {/*</Form>*/}
    </>
}

const DatabaseLoader = () => {
    const navigate = useNavigate();
    const database = databasesApi.useGetDatabaseQuery(useParams().database || "");

    return <AppSecured>
        <AppWrapper>
            <BlockLoading data={database}>
                <Box>
                    <BoxHead>
                        <BoxTitle>Modification de la base e-mail : {database?.data?.name}</BoxTitle>
                    </BoxHead>
                    <BoxBody>
                        {
                            database.data && <Uploader id={database.data.id}/>
                        }
                        <PrimaryCard className="p-3 mb-3">
                            <p>Envoyez votre fichier au format <strong>CSV ou TXT</strong>.<br/>
                            Le fichier doit contenir <strong>1 e-mail par ligne</strong>.<br/>
                            Les e-mails doivent être en clair. Les e-mails sont cryptés dès réception et ne sont <strong>pas lisibles en clair dans Genelead !</strong><br/>
                            La taille maximale du fichier est de <strong>32Mo</strong>.</p>
                            <p className="mb-0"><strong>L'import peut prendre quelques minutes si le fichier est volumineux</strong>.</p>
                        </PrimaryCard>
                        {
                            database.data && <SimpleCard className="p-3 mb-3">
                                <p>
                                    <strong>
                                        Vous pouvez aussi télécharger le fichier existant.
                                    </strong>
                                </p>
                                <p className="text-secondary">
                                    Nombre d'e-mails actuellement dans cette base :&nbsp;
                                    <strong>{database.data.nb_emails}</strong>*.
                                    <br/>
                                    *Le nombre d'e-mails de la base est doublé car nous comptabilisons ceux cryptés aux formats
                                    <strong> MD5 ET SHA256</strong>.
                                </p>
                                <a className="btn btn-primary me-4" href={Urls.front.download(database.data.access_token, "md5")}>
                                    Téléchargement au format MD5
                                </a>
                                <a className="btn btn-primary" href={Urls.front.download(database.data.access_token, "sha256")}>
                                    Téléchargement au format SHA256
                                </a>
                            </SimpleCard>
                        }
                        {/*<Accordion>*/}
                        {/*    <Accordion.Item eventKey="0">*/}
                        {/*        <Accordion.Header>Historique</Accordion.Header>*/}
                        {/*        <Accordion.Body>*/}
                        {/*            <CustomTable>*/}
                        {/*                <thead>*/}
                        {/*                    <tr>*/}
                        {/*                        <th>Mis à jour le</th>*/}
                        {/*                        <th>Nombre total d'emails</th>*/}
                        {/*                    </tr>*/}
                        {/*                </thead>*/}
                        {/*                <tbody>*/}
                        {/*                {*/}
                        {/*                    database.data && database.data.histories && database.data.histories.length > 0*/}
                        {/*                    ? database.data.histories.slice(0).reverse().map((history, index) => {*/}
                        {/*                        let date = new Date(history.created_at);*/}

                        {/*                        return <tr key={index}>*/}
                        {/*                            <td>*/}
                        {/*                                {moment(date).format('lll')}*/}
                        {/*                            </td>*/}
                        {/*                            <td>*/}
                        {/*                                {history.nb_emails}*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}
                        {/*                    })*/}
                        {/*                    : <tr>*/}
                        {/*                        <td className="text-center" colSpan={2}>Aucune donnée</td>*/}
                        {/*                    </tr>*/}
                        {/*                }*/}
                        {/*                </tbody>*/}
                        {/*            </CustomTable>*/}
                        {/*        </Accordion.Body>*/}
                        {/*    </Accordion.Item>*/}
                        {/*</Accordion>*/}
                    </BoxBody>
                    <BoxFooter>
                        {/*<Button onClick={() => navigate(-1)} className="btn btn-secondary fs-14 me-2">Retour</Button>*/}
                    </BoxFooter>
                </Box>
            </BlockLoading>
        </AppWrapper>
    </AppSecured>
}

export default DatabaseLoader;