import {CustomHeaders} from "../../types";
import {StorageManager} from "../../services/storage";

const baseHeaders: CustomHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "Genelead-Client": "customer",
};

export const getCustomHeaders = (headers: Headers, withToken: boolean):Headers => {
    Object.keys(baseHeaders).forEach((key) => {
        headers.set(key, baseHeaders[key]);
    });

    if (withToken) {
        headers.set("Genelead-Token", StorageManager.get() as string);
    }

    return headers;
}

const ApiUrls = {
    //Auth
    login: `/login`,
    logout: `/api/secured/profile/logout`,
    forgotPassword: `/forgot`, // TODO : A Update
    refreshPassword: (token:string) => `/refresh-password/${token}.html`, // TODO : A Update
    passwordChange: `/api/secured/profile/password-update`, // TODO : A Update
    register: `/register`, // TODO : A Update

    //Profile
    me: `/api/secured/profile`,
    informations:{
        get: `/api/secured/profile/informations`,
        edit: `/api/secured/profile/informations/edit`,
    },

    //Forms
    landings:{
        list: `/api/secured/landings`,
    },

    registers:{
        list: "/api/secured/customers/forms/registers",
        register: (id:number|string) => `/api/secured/customers/forms/registers/${id}`,
    },

    //Leads
    leads:{
        downloads: (format = "xlsx", params:Record<string, string> ) => `${process.env.REACT_APP_API_DOMAIN}/api/leads/download/export.${format}?${new URLSearchParams(params).toString()}`,
        periodSummary: `/api/secured/leads/period-summary`,
    },

    //Lists
    list:{
      countries: `/api/secured/lists/countries`,
    },

    //Databases
    databases: {
        list: `/api/secured/databases`,
        edit: (id:number|string) => `/api/secured/databases/${id}`,
        upload: (id:number|string) => `/api/email-databases/${id}/upload`,
    },
}

export default ApiUrls;