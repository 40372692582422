import {profileApi} from "../../services/api/profile";
import {useAppSelector} from "../../store/hooks";
import {getUser} from "../../store/slice/app";
import React from "react";
import Urls from "../../assets/js/Urls";
import {NavLink} from "react-router-dom";
import {IconButton} from "@mui/material";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';

const AppHeader = () => {
    const [logout] = profileApi.useLazyLogoutQuery();
    const user = useAppSelector(getUser);

    return user && <>
        <NavLink to={Urls.me}>
            <IconButton
                color={"secondary"}
                sx={{marginRight: 1}}
            >
                <PersonRoundedIcon/>
            </IconButton>
        </NavLink>
        <IconButton
            color={"error"}
            onClick={() => logout()}
            sx={{marginRight: 1}}
        >
            <PowerSettingsNewRoundedIcon/>
        </IconButton>
    </>
}

export default AppHeader;