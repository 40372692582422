import {ReportingPeriodString} from "./store/slice/reporting/reporting";

export type PeriodDate = [Date, Date];
export type PeriodString = [string, string];

export type CustomHeaders = Record<string, string>;

export type ReactChildren = {
    children: any,
}

export type ReduxRtkApiResult = {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    isFetching?: boolean,
    isUninitialized?: boolean,
    data?: any,
    error?: any,
};

export enum ModalTypes {
    Account,
    DatabasePurge,
};


type Company = {
    name: string,
};

export type userInfos = {
    id: number|null,
    admin: boolean,
    company: Company,
    login:{email: string},
    first_name?: string,
    last_name?: string,
};

export type userInfosUpdateRequest = {
    companyName: string,
    firstName: string,
    lastName: string,
}

export type PasswordUpdateRequest = {
    password_old: string,
    password_new: string,
    password_confirm: string,
};


export type FormsData = {
    body?: string,
    cta?: string,
    excerpt: string,
    fields_color: string,
    form_design: string,
    modal_version: string,
    optin: string,
    optin_mode: number,
    optin_name: string,
    subtitle: string,
    title: string,
}

export type Forms = {
    id: number,
    name: string,
    code: number,
    data: any,
    database_id: number,
    database_name: string,
    domain: string,
    formRegisters: number,
    landingCode: string,
    landing: Landing,
    current_templates: Array<number>,
}

export type DatabaseEditRequest = {
    body: FormData,
    database_id: number|string,
}

export type Common = {
    backup:Array<string>,
    crypting:Array<string>,
    hosting:string,
    legalBase:Array<string>,
    tracking:Array<string>,
}

export type RegisterDates = {
    created: number,
    updated: number,
}

export type Register = {
    common: Common,
    fields: any,
    found: number
    is_sensitive_data: boolean,
    form: Pick<Forms, "name" | "landing">,
    objectives: string,
    retention_month: number,
    sensitive_data_detail: any,
    subcontractors: string,
    target: string,
    timestamps: RegisterDates,
    transfert_countries: Array<string>,
}

export type Country = {
    code: string,
    name: string,
}

export type ReportingEntry = {
    customCode: string,
    date: string,
    form: string,
    form_id: number,
    nb: number,
    userApproval: boolean,
}

export type ReportingRequest = {
    period: ReportingPeriodString,
    last: number,
};

export type ReportingResponse = Array<ReportingEntry>;



//New types 2024


export type History = {
    created_at : number,
    nb_emails : number,
}

export type LoginArgs = {
    email: string,
    password: string
}

export type LoginResponse = {
    email: string,
    access_token?: string,
};

export type User = {
    email: string,
    isCustomerAdmin: boolean,
};

export type Database = {
    access_token: string,
    histories: Array<History>,
    nb_emails?: number,
    forms?: Array<{name:string}>,
    id: number,
    nb_phones: number,
    name: string,
}

export type CustomForm = {
    id: number,
    landing: Landing,
    form_register: FormRegister,
}

export type FormRegister = {
    id: number,
}

export type Landing = {
    code: string,
    name: string,
    enabled_templates: Array<number>,
    landing_option: LandingOption | null,
    fields: any,
    site: Site,
}

export type LandingOption = {
    email_database: EmailDatabase,
}

export type Site = {
    domain: string,
}

export type EmailDatabase = {
    id: number,
    name: string,
    access_token: string,
}