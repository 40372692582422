import React from "react";
import {Box, Grid} from "@mui/material";
import logo from './assets/img/logo.svg';
import {ReactChildren} from "./types";

export const AuthAppTitle:React.FC = ({children}) => <h1 className="text-xl auth-app-title">{children}</h1>

type AppPublicProps = {
    page: "login" | "forgot" | "reinit" | "register"
} & ReactChildren;

const AppPublic = ({children, page}: AppPublicProps) => {
  return <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      id={"auth-wrapper"}
      sx={{
          "padding": "48px",
      }}
    >
      <AuthBox page={page}>
          <img className={"auth-logo"} src={logo} alt={"Logo"}/>
          {children}
      </AuthBox>
  </Grid>
}

export default AppPublic

export const AuthBox = ({children, page}:AppPublicProps) => {
    return <Box
        id={"auth-box"}
        sx={{
            width: page === "login" ? 400 : 600,
            mx: "auto",
        }}
    >{children}</Box>
}