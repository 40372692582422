import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {StorageManager} from "../../services/storage";
import authApi from "../../services/api/auth";
import Urls from "../../assets/js/Urls";
import {RootState} from "../store";
import {profileApi} from "../../services/api/profile";
import {User} from "../../types";
import databasesApi from "../../services/api/databases";

interface AppState {
    user: User|null,
    redirect: string|null,
    registerSuccess: boolean,
    forgottenSuccess:boolean,
    refreshSuccess: boolean,
    tries: number|null|undefined,
    navOpen: boolean,
};

const initialState: AppState = {
    user: null,
    redirect: null,
    registerSuccess: false,
    forgottenSuccess: false,
    refreshSuccess: false,
    tries: undefined,
    navOpen: true,
};

const app = createSlice({
    name: "app",
    initialState,
    reducers: {
        clearRedirect: (state: AppState) => {
            state.redirect = null;
        },
        redirect: (state: AppState, action: PayloadAction<string>) => {
            state.redirect = action.payload;
        },
        clearAuthState: (state: AppState) => {
            state.registerSuccess = false;
            state.forgottenSuccess = false;
            state.refreshSuccess = false;
            state.tries = undefined;
        },
        toggleOpen: (state) => {
            state.navOpen = !state.navOpen;
        },
        setNavOpen: (state, action: PayloadAction<boolean>) => {
            state.navOpen = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
                state.tries = undefined;
            })
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                StorageManager.set(action.payload?.access_token as string);
                window.location.href = Urls.home;
            })
            .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
                console.log(action.payload);
            })
            .addMatcher(profileApi.endpoints.logout.matchFulfilled, (state, action) => {
                StorageManager.clear();
                window.location.href = Urls.login;
            })
            .addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, action) => {
                if (null === state.user) {
                    state.user = action.payload;
                }
            })
            .addMatcher(databasesApi.endpoints.deleteDatabase.matchFulfilled, (state) =>{
                state.redirect = Urls.forms;
            })
        ;
    }
});

export const {
    setNavOpen,
} = app.actions;
export const appClearAuthState = app.actions.clearAuthState;
export const appRedirect = app.actions.redirect;
export const appClearRedirect = app.actions.clearRedirect;

export const getRedirectUrl = (state: RootState) => state.app.redirect;
export const getUser = (state: RootState) => state.app.user;
export const getTries = (state: RootState) => state.app.tries;
export const isCustomerAdmin = (state: RootState) => state?.app?.user?.isCustomerAdmin;
export const getNavOpen = (state: RootState) => state.app.navOpen;

export default app.reducer;
