import React from "react";
import AppSecured from "../../AppSecured";
import {AppWrapper} from "../utils/Blocks";
import {useAppSelector} from "../../store/hooks";
import {isCustomerAdmin} from "../../store/slice/app";
import {Paper, Tabs, Tab, Box, Typography} from "@mui/material";
import Reporting from "./Reporting";
import DatabasesList from "./databases/DatabasesList";
import LeadList from "./leads/LeadList";

type HomeTabs = {
    tab?:string,
}

type TabPanelProps = {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function TabProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const DashboardHome:React.FC<HomeTabs> = (props) => {
    const isAdmin = useAppSelector(isCustomerAdmin);
    const [tab, setTab] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newTab: number) => {
        setTab(newTab);
    };
    return <AppSecured>
        <AppWrapper>
            <Paper elevation={0}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab label={"Vue d'ensemble"} {...TabProps(0)}/>
                    <Tab label={"Export des leads"} {...TabProps(1)}/>
                    <Tab label={"Bases E-mails"} {...TabProps(2)}/>
                </Tabs>
                <CustomTabPanel index={0} value={tab}>
                    <Reporting/>
                </CustomTabPanel>
                <CustomTabPanel index={1} value={tab}>
                    <LeadList/>
                </CustomTabPanel>
                <CustomTabPanel index={2} value={tab}>
                    <DatabasesList/>
                </CustomTabPanel>
            </Paper>
        </AppWrapper>
    </AppSecured>
}

export default DashboardHome;