import AppPublic, {AuthAppTitle} from "../../AppPublic";
import {CustomAlert} from "../utils/CustomAlerts";
import {FormattedMessage} from "react-intl";
import {Link, useParams} from "react-router-dom";
import Urls from "../../assets/js/Urls";
import {BlockError} from "../utils/Blocks";
import React from "react";
import authApi from "../../services/api/auth";
import SimpleForm from "../misc/SimpleForm";
import {FormControl, InputAdornment, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";

const RefreshPassword = () => {
    const [refresh, refreshResult] = authApi.useLazyRefreshPasswordQuery();
    const newToken = new URLSearchParams(window.location.search).get("token") || "";

    return <AppPublic page={"reinit"}>
        {
            refreshResult.isSuccess
                ?<>
                    <CustomAlert variant="success"><p className="text-center mb-0"><FormattedMessage id="refreshSuccess" values={{
                        br: <br/>,
                    }}/> </p></CustomAlert>
                    <p className="text-center"><Link to={Urls.login} className="ms-1"><FormattedMessage id="login"/></Link></p>
                </>
                :<>
                    <SimpleForm onSubmit={(data) =>
                        refresh({
                            token: newToken,
                            data: data as FormData,
                        })}>
                        <AuthAppTitle><FormattedMessage id="passwordRefresh"/></AuthAppTitle>
                        <FormControl
                            fullWidth={true}
                            sx={{mb: 2}}
                        >
                            <TextField
                                name="password"
                                type="password"
                                placeholder="Mot de passe"
                                size={"small"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlinedIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth={true}
                            sx={{mb: 2}}
                        >
                            <TextField
                                name="confirmation"
                                type="password"
                                placeholder="Confirmation"
                                size={"small"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlinedIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>

                        <Button
                            variant={"contained"}
                            type={"submit"}
                        ><FormattedMessage id="passwordRefreshBtn"/></Button>
                    </SimpleForm>
                    <BlockError data={refreshResult} />
                    <Typography
                        align={"center"}
                        className={"text-xs"}
                        sx={{marginTop:"16px"}}
                    >
                        <Link to={Urls.login} className={"text-md"}>
                            <FormattedMessage id="login"/>
                        </Link>
                    </Typography>
                </>
        }
  </AppPublic>
}

export default RefreshPassword;