import {profileApi} from "./profile";
import ApiUrls from "../../assets/js/ApiUrls";
import {
    ReportingRequest, ReportingResponse,
} from "../../types";


const reportingApi = profileApi.injectEndpoints({
    endpoints: build => ({
        getReporting: build.query<ReportingResponse, ReportingRequest>({
            query: (params) => ({
                url: ApiUrls.leads.periodSummary,
                params: {
                    from: params.period[0],
                    to: params.period[1],
                    last: params.last,
                }
            }),
        }),
    }),
});

export default reportingApi;
