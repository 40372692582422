import {DatePeriod} from "../../../components/misc/DatePeriod";

const fr:Record<string, string> = {
    // Login
    login: "Connexion",
    loginConnect: "Se connecter",
    loginForgotPassword: "Mot de passe oublié ?",
    loginNoAccount: "Pas encore d'accès ?",

    //Register
    register: "Inscription",
    registerOptin: "J'accepte les <a1>conditions générales d'utilisation</a1>",
    registerValidate: "S'inscrire (CGU à valider)",
    registerAlreadyAccount: "J'ai déjà un compte Genelead",
    registerSuccess: "Compte créé avec succès :){br}Vous allez recevoir un e-mail pour générer votre mot de passe.",

    //ForgotPassword
    passwordReinit: "Réinitialiser mon mot de passe",
    forgottenSuccess: "Vous allez recevoir un email pour générer un nouveau mot de passe",

    //RefreshPassword
    passwordRefresh: "Changement de mot de passe",
    passwordRefreshBtn: "Changer de mot de passe",
    refreshSuccess: "Mot de passe changé avec succès {br} Vous pouvez à présent vous reconnecter",

    //Globals
    pleaseWait: "Veuillez patienter",
    cancel: "Annuler",
    validate: "Valider",

    //Messages
    updatePasswordSuccess: "Mot de passe mis à jour avec succès",
    updateInformationsSuccess: "Informations de compte mises à jour avec succès",
    deleteDatabaseSuccess: "La base de données à bien été supprimmée",
    databaseUploadSuccess: "La base de données à bien été mise à jour",

    //LitePicker
    "datepicker-period-selected": "Du {from} au {to}",
    "datepicker-today": "Aujourd'hui",
    "datepicker-yesterday": "Hier",
    "datepicker-this-week": "Cette semaine",
    "datepicker-this-month": "Ce mois ci",
    "datepicker-previous-month": "Le mois précédent",
    "datepicker-this-year": "Cette année",
};

export default fr;
