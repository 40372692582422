import React from "react";
import databasesApi from "../../../services/api/databases";
import BlockLoading, {LoadingSkeleton} from "../../utils/Blocks";
import Urls from "../../../assets/js/Urls";
import {MoreActionsMenu} from "../../utils/Buttons";
import {
    Box,
    Chip,
    Link, Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import ApiUrls from "../../../assets/js/ApiUrls";
import {StorageManager} from "../../../services/storage";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import Button from "@mui/material/Button";
import {openModalDatabasePurge} from "../../../store/slice/modals/modals";
import {useAppDispatch} from "../../../store/hooks";


const DatabasesList = () => {
    const dispatch = useAppDispatch();
    const databasesList = databasesApi.useGetDatabasesListQuery();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return <Box p={2}>
        <LoadingSkeleton height={500} data={databasesList} sx={{transform: "none"}}>
            {
                databasesList.data && <>
                    <TableContainer
                        sx={{ maxHeight: "calc(100vh - 72px - 48px - 52px - 48px - 32px)"}}>
                        <Table stickyHeader size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Opérations</TableCell>
                                    <TableCell>Quantité E-mails</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    databasesList.data
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(database => <TableRow key={database.id} hover>
                                            <TableCell>({database.id}) {database.name}</TableCell>
                                            <TableCell>
                                                {
                                                    database.forms && database.forms.map(form => <Chip key={form.name} variant={"filled"} size={"small"} sx={{marginRight:1, marginBottom:1}} label={form.name}/>)
                                                }
                                            </TableCell>
                                            <TableCell>{database.nb_emails}</TableCell>
                                            <TableCell>
                                                <MoreActionsMenu>
                                                    <MenuItem>
                                                        <Link
                                                            href={Urls.databasesLoader(database.access_token)}
                                                            target={"_blank"}
                                                            underline={"none"}
                                                            sx={{color: "#000"}}
                                                            color={"primary"}>Éditer</Link>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => dispatch(openModalDatabasePurge(database))} sx={{color: "#F00"}}>
                                                        Supprimer
                                                    </MenuItem>
                                                </MoreActionsMenu>
                                            </TableCell>
                                        </TableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, {value:-1, label:"Tout"}]}
                        component="div"
                        count={databasesList.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Par page :"}
                        labelDisplayedRows={({from, to, count})=> `${from}-${to} sur ${count}`}
                    />
                </>
            }
        </LoadingSkeleton>
    </Box>
}

export default DatabasesList;