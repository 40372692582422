import React from "react";

const TrialsInfo:React.FC<{
    tries: number|null|undefined,
}> = ({tries}) => {
    return <>
        {
            tries && tries >= 0 && tries < 3 && <p key="tries_info" className="text-center text-danger mb-0 mt-3">
                {
                    tries < 1
                        ?  <span>Nombre de tentatives épuisé ! <br/> Vous allez recevoir un e-mail pour réinitialiser votre mot de passe. </span>
                        : `${tries} tentative${tries === 1 ? "" : "s" } restante${tries === 1 ? "" : "s" } !`
                }
            </p>
        }
    </>
}

export default TrialsInfo;