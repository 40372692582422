import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../hooks";
import {Navigate} from "react-router-dom";
import {appClearRedirect, getRedirectUrl} from "./app";

const AppRedirect:React.FC = () => {
    const redirectUrl = useAppSelector(getRedirectUrl);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (redirectUrl) {
            dispatch(appClearRedirect());
        }
    }, [redirectUrl]);

    return redirectUrl ? <Navigate to={redirectUrl} /> : null;
};

export default AppRedirect;