import {ChartConfiguration, ChartData} from "chart.js";
import {Reporting, Kpi} from "../../../store/slice/reporting/reporting";

import {sumReducer, uniqueReducer} from "../utils";

const getTooltipCallback = (metric:Kpi) => (context: any) => {
    const value = new Number(context.parsed.y);

    return `${context.dataset.label}: ${value}`;
}

export const DailyLeadsConfig = (rawData:Reporting):ChartConfiguration<"line", Array<number>> => {

    const dates = rawData.map(row => row.date).reduce(uniqueReducer, []).sort(),
        products = rawData.map(row => row.form).reduce(uniqueReducer, []).sort();

    const colors = [
        "FFC312",
        "EA2027",
        "C4E538",

        "006266",
        "12CBC4",
        "0652DD",

        "009432",
        "D980FA",
        "6F1E51",

        "1B1464",
        "FDA7DF",
        "9980FA",
    ];

    let datasets = products.map((p, index) => ({
        label: p,
        backgroundColor: "#"+colors[index] || "#ff0000",
        borderColor: "#"+colors[index] || "#ff0000",
        borderWidth: 3,
        fill: false,
        tension: 0.3,
        pointRadius: 3,
        data: dates.map(date => {
            return rawData
                .filter(row => row.date === date && row.form === p)
                .map(row => row.nb)
                .reduce(sumReducer, 0)
                ;
        })
    }));

    const data:ChartData<"line", number[], unknown> = {
        labels: dates,
        datasets: datasets,
    }

    return {
        type: "line",
        data: data,
        options: {
            responsive:true,
            plugins: {
                title: {
                    display: true,
                    text: "Vue par jour et opération",
                    font: {
                        size: 24,
                    },
                    color: "#171717",
                },
                tooltip: {
                    callbacks: {
                        label: getTooltipCallback("nb"),
                    }
                }
            },
            elements: {
                point: {
                    borderWidth: 0,
                    radius: 0,
                },
            },

            scales: {
                x: {
                    ticks: {
                        autoSkip: true,
                        maxRotation: 0,
                    },
                    stacked: true,
                },
                y: {
                    stacked: false,
                    title:{
                        display: true,
                        text: "Leads",
                    }
                }
            }
        }
    }
};

export const DailyTotalLeadsConfig = (rawData:Reporting):ChartConfiguration<"line", Array<number>> => {

    const dates = rawData.map(row => row.date).reduce(uniqueReducer, []).sort(),
          products = rawData.map(row => row.form).reduce(uniqueReducer, []).sort();

    let totalDatasets = [];
    let datasets = products.map((p, index) => ({
        label: p,
        fill: false,
        data: dates.map(date => {
            return rawData
                .filter(row => row.date === date && row.form === p)
                .map(row => row.nb)
                .reduce(sumReducer, 0)
                ;
        })
    }));

    if (datasets.length > 1) {
        totalDatasets.push({
            label: "Total",
            backgroundColor: "#ff8080",
            borderColor: "#ff8080",
            borderWidth: 3,
            tension: 0.3,
            fill: false,
            pointRadius: 3,
            data: dates.map(date => rawData
                .filter(row => row.date === date)
                .map(row => row.nb)
                .reduce(sumReducer, 0)
            ),
        })
    }

    const data = {
        labels: dates,
        datasets:  totalDatasets,
    }

    return {
        type: "line",
        data: data,
        options: {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: "Vue globale par jour",
                    font: {
                        size: 24,
                    },
                    color: "#171717",
                },
            },
            elements: {
                point: {
                    borderWidth: 0,
                    radius: 0,
                },
            },

            scales: {
                x: {
                    ticks: {
                        autoSkip: true,
                        maxRotation: 0,
                    },
                    stacked: true,
                },
                y: {
                    stacked: false,
                    title:{
                        display: true,
                        text: "Leads",
                    }
                }
            }
        }
    }
};