const Urls = {
    //Auth
    login: "/",
    register: "/register",
    forgot: "/forgot",
    passwordReinit: (token:string) => `/refresh_password.html`,

    //Dashboard
    home: "/admin/dashboard",
    forms: "/admin/dashboard/forms",
    formRegisters: "/admin/dashboard/forms/register/search",
    databasesLoader: (token:string) => `https://api.genelead.fr/databases/${token}.html`,
    leads: "/admin/dashboard/leads",

    //Profile
    me: "/account",

    //Front
    front:{
        download: (token:string, format:string) => `https://www.genelead.fr/databases/${token}/${format}.txt`,
        template: (domain:string, slug:string, template:number) => `///${domain}/${slug}/${template}/index.html`,
    }
}

export default Urls;