import ApiUrls, {getCustomHeaders} from "../../assets/js/ApiUrls";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {LoginArgs, LoginResponse} from "../../types";

function buildFormData(formData:FormData, data:any, parentKey?:any) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        let value = data == null ? '' : data;
        if (typeof value === 'boolean' && value === false) { value = '0'; }
        formData.append(parentKey, value);
    }
}

export function jsonToFormData(data:any) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

type RefreshPasswordRequest = {
    token: string,
    data: FormData,
}

const authApi = createApi({
    reducerPath: "api.auto",
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, store) => getCustomHeaders(headers, false),
        baseUrl: process.env.REACT_APP_API_DOMAIN,
    }),
    endpoints: (build) => ({
        // login: build.query<LoginResponse, FormData>({
        login: build.query<LoginResponse, LoginArgs>({
            query: (formData) => ({
                url: ApiUrls.login,
                method: "POST",
                body: formData,
            }),
        }),
        forgotPassword: build.query<any, FormData>({
            query: (formData) => ({
                url: ApiUrls.forgotPassword,
                method: "POST",
                body: formData,
            }),
        }),
        refreshPassword: build.query<any, RefreshPasswordRequest>({
            query: (query) => ({
                url: ApiUrls.refreshPassword(query.token),
                method: "POST",
                body: query.data,
            }),
        }),
        register: build.query<any, FormData>({
            query: (data) => ({
                url: ApiUrls.register,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export default authApi;