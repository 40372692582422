import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

interface TabsState {
    data: { keyTab: any|undefined },
};

const initialState: TabsState = {
    data: { keyTab: undefined },
};

const tabs = createSlice({
    name: "tabs",
    reducers: {
        changeDataTabs: (state, action: PayloadAction<string>) => {
            state.data.keyTab = action.payload;
        },
    },
    initialState,
});

export const changeDataTabs = tabs.actions.changeDataTabs;
export const getDataTabs = (state: RootState) => state.tabs.data.keyTab;

export const tabsReducer = tabs.reducer;