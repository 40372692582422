import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import ApiUrls, {getCustomHeaders} from "../../assets/js/ApiUrls";
import {Database, DatabaseEditRequest} from "../../types";

const databasesApi = createApi({
    reducerPath: "api.databases",
    baseQuery: fetchBaseQuery({
        prepareHeaders:(headers, store) => getCustomHeaders(headers, true),
        baseUrl: process.env.REACT_APP_API_DOMAIN,
    }),
    tagTypes:["database", "databaseslist"],
    endpoints: (build) => ({
        getDatabase: build.query<Database, string>({
            query: (params) => ApiUrls.databases.edit(params),
            providesTags: ["database"],
        }),
        getDatabasesList:build.query<Array<Database>, void>({
            query: (params) => ApiUrls.databases.list,
            providesTags: ["databaseslist"],
        }),
        deleteDatabase: build.mutation<any, number>({
            query: (params) => ({
                url: ApiUrls.databases.edit(params),
                method: "DELETE",
                invalidatesTags: ["database"],
            })
        }),
        databaseUpload: build.mutation<any, DatabaseEditRequest>({
            query: (params) => ({
                url : ApiUrls.databases.upload(params.database_id),
                method: "POST",
                body: params.body,
            })
        })
    })
});

export default databasesApi;