import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import Urls from "../../assets/js/Urls";
import {addNotification} from "../slice/notifications/notifications";
import {appRedirect} from "../slice/app";

export const httpErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const status:string = (action.payload.status).toString();

        switch (status) {
            case "401":
                api.dispatch(appRedirect(Urls.login));
                break;

            case "403":
                api.dispatch(addNotification({
                    type: "danger",
                    message: "Accès à une ressource non autorisé",
                }));
                break;

            case "404":
                api.dispatch(addNotification({
                    type: "danger",
                    message: "Accès à une ressource inconnue",
                }));
                break;

            case "500":
                api.dispatch(addNotification({
                    type: "danger",
                    message: action.payload?.data?.error || "Erreur serveur",
                }));
                break;

            case "PARSING_ERROR":
                api.dispatch(addNotification({
                    type: "danger",
                    message: "Erreur serveur inconnue",
                }));
                break;

            case "login_error":
                api.dispatch(addNotification({
                    type: "danger",
                    message: "Identifiant invalide",
                }));
                break;

            default:
                api.dispatch(addNotification({
                    type: "danger",
                    message: `Erreur HTTP non prise en charge (${status})`,
                }));
                break;
        }
    }

    return next(action);
}