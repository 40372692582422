import React, {useEffect, useMemo} from "react";
import {useAppSelector} from "../../../store/hooks";
import {getReportingData, getReportingSearch} from "../../../store/slice/reporting/reporting";
import {sumReducer, uniqueReducer} from "../../../assets/js/utils";
import DatePicker from "../../misc/DatePicker";
import {ReportingPeriodToString} from "../../misc/DatePeriod";
import ApiUrls from "../../../assets/js/ApiUrls";
import {StorageManager} from "../../../services/storage";
import {
    Grid,
    Link,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import reportingApi from "../../../services/api/reporting";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const LeadList = () => {
    const period = ReportingPeriodToString(useAppSelector(getReportingSearch));
    const [query, {isLoading, isFetching}] = reportingApi.useLazyGetReportingQuery();

    useEffect(()=>{
        query({
            period,
            last: Math.random(),
        })
    }, [JSON.stringify(period)])

    return <>
        <Grid container p={2} spacing={2}>
            <Grid item xs={"auto"} sx={{marginRight: "auto"}}>
                <DatePicker id="leads-datepicker" />
            </Grid>
            {
                (isLoading || isFetching)
                    ? <Skeleton height={58} width={360} sx={{transform: "none"}}/>
                    : <Grid item xs={"auto"}>
                        <Button
                            component={Link}
                            href={ApiUrls.leads.downloads("xlsx", {
                                from: period[0],
                                to: period[1],
                                access_token: StorageManager.get(),
                            })}
                            target={"_blank"}
                            variant={"contained"}
                            sx={{marginRight:1}}
                            color={"primary"}>Export liste</Button>
                        <Button
                            component={Link}
                            href={ApiUrls.leads.downloads("custom.xlsx", {
                                from: period[0],
                                to: period[1],
                                access_token: StorageManager.get(),
                            })}
                            target={"_blank"}
                            variant={"contained"}
                            color={"secondary"}>Export personnalisé</Button>
                    </Grid>
            }
            {
                (isLoading || isFetching)
                    ? <Skeleton height={240} width={"100%"} sx={{transform: "none", marginTop:2}}/>
                    : <Grid item xs={12}><LeadListTable/></Grid>
            }
        </Grid>
    </>
}

export const LeadListTable = () => {
    const period = ReportingPeriodToString(useAppSelector(getReportingSearch));
    const reporting = useAppSelector(getReportingData);

    const reportingTable = useMemo(() => {
        if (reporting) {
            const uniqueForms = reporting.map(row => row.form).reduce(uniqueReducer, []);

            let products = uniqueForms.map(form => {
                const filteredData = reporting.filter(row => row.form === form);
                const customCode = filteredData.map(row => row.customCode).reduce(uniqueReducer, []);
                const res = {
                    form,
                    customCode: customCode.length > 0 ? customCode[0] : null,
                    optin: filteredData.filter(row => row.userApproval).map(row => row.nb).reduce(sumReducer, 0),
                    optout: filteredData.filter(row => !row.userApproval).map(row => row.nb).reduce(sumReducer, 0),
                    form_id: filteredData.map(row => row.form_id).reduce(uniqueReducer, []),
                };
                return res;
            })

            return products;
        }
        return null;
    }, [reporting]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return <>
        <TableContainer sx={{ maxHeight: "calc(100vh - 72px - 48px - 52px - 48px -16px)"}}> {/*all - header - tabs - pagination - paddingbottom - margintop*/}
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Opérations</TableCell>
                    <TableCell>Codes média</TableCell>
                    <TableCell>Leads OPT-IN</TableCell>
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    reportingTable && reportingTable
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(p => <TableRow key={`summary-${p.form}`} hover>
                            <TableCell>{p.form}</TableCell>
                            <TableCell>{p.customCode}</TableCell>
                            <TableCell>{p.optin}</TableCell>
                            <TableCell align={"right"}>
                                <Button
                                    component={Link}
                                    href={ApiUrls.leads.downloads("xlsx",{
                                        from: period[0],
                                        to: period[1],
                                        access_token: StorageManager.get(),
                                        form: p.form_id.toString(),
                                    })}
                                    target={"_blank"}
                                    variant={"contained"}
                                    sx={{padding:1, minWidth: "auto"}}
                                    color={"primary"}><DownloadRoundedIcon/></Button>
                            </TableCell>
                        </TableRow>)
                }
                {
                    reportingTable && reportingTable.length == 0 && <TableCell colSpan={4} align={"center"}><Typography className={"text-md"}>Aucun résultat trouvé</Typography></TableCell>
                }
            </TableBody>
        </Table>
    </TableContainer>
        { reportingTable && <TablePagination
            disabled={reportingTable.length == 0}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={reportingTable?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Par page :"}
        labelDisplayedRows={({from, to, count})=> `${from}-${to} sur ${count}`}
    />}
</>
}

export default LeadList;