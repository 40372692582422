import React from "react";
import {profileApi} from "./services/api/profile";
import Urls from "./assets/js/Urls";
import BlockLoading from "./components/utils/Blocks";
import logo from "./assets/img/logo-white.svg"
import AppHeader from "./components/utils/AppHeader";
import {NavLink} from "react-router-dom";
import BarChartIcon from '@mui/icons-material/BarChartRounded';
import TuneIcon from '@mui/icons-material/TuneRounded';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import {isCustomerAdmin} from "./store/slice/app";
import {useAppSelector} from "./store/hooks";
import Grid from '@mui/material/Unstable_Grid2';
import {Box} from "@mui/material";
import AppSnackbar from "./store/slice/notifications/AppSnackbar";
import ModalDatabasePurge from "./store/slice/modals/databases/ModalDatabasePurge";

const AppMenu = () => {
    const isAdmin = useAppSelector(isCustomerAdmin);

    return <>
        <NavLink to={Urls.home} end>
            <BarChartIcon sx={{marginBottom:1}}/>
            Données
        </NavLink>
        {
            isAdmin && <>
                <NavLink to={Urls.forms} end>
                    <TuneIcon sx={{marginBottom:1}}/>
                    Opérations
                </NavLink>
                <NavLink to={Urls.formRegisters}>
                    <GavelRoundedIcon sx={{marginBottom:1}}/>
                    RGPD
                </NavLink>
            </>
        }
    </>
}

const AppSecured: React.FC = ({children}) => {

    const data = profileApi.useGetProfileQuery();

    return <BlockLoading data={data}>
        <Grid
            className={"genelead-app"}
        >
            <nav className={`sidenav`}>
                <Box className="nav-head">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <NavLink to={Urls.home}>
                            <img src={logo} className={"logo"} alt="Genelead"/>
                        </NavLink>
                    </Box>
                </Box>
                <Box
                    className={"side-menu"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <AppMenu/>
                </Box>
            </nav>
            <header className="head">
                <AppHeader/>
            </header>
            <main>
                {children}
            </main>
            <AppSnackbar/>
            <AppModals/>
        </Grid>
    </BlockLoading>;
};

export default AppSecured;

const AppModals = () => {
    return <>
        <ModalDatabasePurge/>
    </>
}
