import AppPublic, {AuthAppTitle} from "../../AppPublic";
import authApi from "../../services/api/auth";
import {CustomAlert} from "../utils/CustomAlerts";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import Urls from "../../assets/js/Urls";
import React from "react";
import {BlockError} from "../utils/Blocks";
import {Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Button from "@mui/material/Button";
import SimpleForm from "../misc/SimpleForm";

const Register = () => {

    const [register, registerResult] = authApi.useLazyRegisterQuery();

    return <AppPublic page={"register"}>
        {
            registerResult.isSuccess
            ?<>
                <CustomAlert variant="success"><p className="text-center mb-0"><FormattedMessage id="registerSuccess" values={{br: <br />}}/></p></CustomAlert>
                <p className="help-block fw-medium text-center"><Link to={Urls.login} className="ms-1"><i className="bi bi-chevron-left"/><FormattedMessage id="login"/></Link></p>
            </>
            :<>
                <SimpleForm onSubmit={(data) => register(data as FormData)}>
                    <AuthAppTitle><FormattedMessage id="register"/></AuthAppTitle>
                    <FormControl
                        fullWidth={true}
                        sx={{mb: 2}}
                    >
                        <TextField
                            name="company.name"
                            type="text"
                            placeholder="Raison sociale"
                            size={"small"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <ApartmentOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth={true}
                                sx={{mb: 2}}
                            >
                                <TextField
                                    name="last_name"
                                    type="text"
                                    placeholder="Nom"
                                    size={"small"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth={true}
                                sx={{mb: 2}}
                            >
                                <TextField
                                    name="first_name"
                                    type="text"
                                    placeholder="Prénom"
                                    size={"small"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonOutlineOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormControl
                        fullWidth={true}
                        sx={{mb: 1}}
                    >
                        <TextField
                            name="login.email"
                            type="text"
                            placeholder="E-mail"
                            size={"small"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MailOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <FormControlLabel
                        control={
                            <Checkbox id="registerOptin" sx={{ color: "primary.main" }} required />
                        }
                        label={
                            <FormattedMessage
                                id="registerOptin"
                                values={{
                                    a1: (chunks: string) => (
                                        <Link to="/cgu.pdf" target="_blank" rel="noreferrer">
                                            {chunks}
                                        </Link>
                                    )
                                }}
                            />
                        }
                    />

                    <Button
                        variant={"contained"}
                        type={"submit"}
                        endIcon={<ArrowForwardRoundedIcon/>}
                    ><FormattedMessage id="registerValidate"/></Button>
                </SimpleForm>
                    <BlockError data={registerResult} />
                    <Typography
                        align={"center"}
                        className={"text-xs"}
                        sx={{marginTop:"16px"}}
                    >
                        <FormattedMessage id="registerAlreadyAccount"/><br/>
                        <Link to={Urls.login} className={"text-md"}>
                            <FormattedMessage id="login"/>
                        </Link>
                    </Typography>

            </>
        }
   </AppPublic>
}

export default Register;