import AppPublic, {AuthAppTitle} from "../../AppPublic";
import {Link} from "react-router-dom";
import Urls from "../../assets/js/Urls";
import {FormattedMessage} from "react-intl";
import React from "react";
import authApi from "../../services/api/auth";
import {CustomAlert} from "../utils/CustomAlerts";
import {BlockError} from "../utils/Blocks";
import SimpleForm from "../misc/SimpleForm";
import {FormControl, InputAdornment, TextField, Typography} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Button from "@mui/material/Button";

const ForgotPassword = () => {
    const [forgot, forgotResult] = authApi.useLazyForgotPasswordQuery();

    return <AppPublic page={"forgot"}>
        {
            forgotResult.isSuccess
            ?<>
                <CustomAlert variant="success"><p className="text-center mb-0"><FormattedMessage id="forgottenSuccess"/> </p></CustomAlert>
                <p className="text-center"><Link to={Urls.login} className="ms-1"><FormattedMessage id="login"/></Link></p>
            </>
            :<>
                <SimpleForm onSubmit={(data) => forgot(data as FormData)}>
                    <AuthAppTitle><FormattedMessage id="loginForgotPassword"/></AuthAppTitle>
                    <FormControl
                        fullWidth={true}
                        sx={{mb: 2}}
                    >
                        <TextField
                            name="email"
                            type="text"
                            placeholder="E-mail"
                            size={"small"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutlineOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <Button
                        variant={"contained"}
                        type={"submit"}
                    ><FormattedMessage id="passwordReinit"/></Button>
                </SimpleForm>
                <BlockError data={forgotResult} />
                    <Typography
                        align={"center"}
                        className={"text-xs"}
                        sx={{marginTop:"16px"}}
                    >
                        <Link to={Urls.login} className={"text-md"}>
                            <FormattedMessage id="login"/>
                        </Link>
                    </Typography>
            </>
        }
    </AppPublic>
}

export default ForgotPassword;