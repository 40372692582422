import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import ApiUrls, {getCustomHeaders} from "../../assets/js/ApiUrls";
import {PasswordUpdateRequest, User, userInfos, userInfosUpdateRequest} from "../../types";
import {jsonToFormData} from "./auth";

export const profileApi = createApi({
    reducerPath: "api.profile",
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, store) => getCustomHeaders(headers, true),
        baseUrl: process.env.REACT_APP_API_DOMAIN,
    }),
    tagTypes:["userInfos"],
    endpoints: (build) => ({
        logout: build.query<void, void>({
            query: () => ({
                url: ApiUrls.logout,
                method: "POST",
            })
        }),
        getProfile: build.query<User, void>({
            query: () => ({
                url: ApiUrls.me,
                method: "GET",
            })
        }),
        getInformations: build.query<userInfos, void>({
            query: () => ({
                url: ApiUrls.informations.get,
                method: "GET",
            }),
            providesTags: ["userInfos"],
        }),
        changeInformations: build.mutation<any, userInfosUpdateRequest>({
            query: (data) => ({
                url: ApiUrls.informations.edit,
                method: "POST",
                body: jsonToFormData(data),
            }),
            invalidatesTags: ["userInfos"],
        }),
        passwordChange: build.mutation<any, PasswordUpdateRequest>({
            query: (data) => ({
                url: ApiUrls.passwordChange,
                method: "POST",
                body: jsonToFormData(data),
            }),
        }),
    }),
});
