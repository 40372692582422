import React from "react";

export const Cards:React.FC<{
    nb: string,
    label: string,
    type?: string,
    icon?: string,
}> = ({nb,label,type= "primary",icon= "icon-plus"}) => <div className="card mb-4">
    <div className="card-body flexbox-b">
        <div className="easypie mr-4">
                <span className={`easypie-data text-${type}`} style={{fontSize: "32px"}}>
                    <i className={`icon ${icon}`}/>
                </span>
        </div>
        <div>
            <h3 className={`font-strong text-${type}`}>{nb}</h3>
            <div className="text-muted">{label}</div>
        </div>
    </div>
</div>

type CardsType = {
    className?:string
}

export const PrimaryCard:React.FC<CardsType> = ({children, className}) => {
    return <div className={`card-custom card-blue ${className}`} >
        {
            children
        }
    </div>
}
export const SimpleCard:React.FC<CardsType> = ({children, className}) => {
    return <div className={`card-custom simple-card ${className}`} >
        {
            children
        }
    </div>
}