import React, {useEffect} from "react";
import AppPublic, {AuthAppTitle} from "../../AppPublic";
import {FormattedMessage} from "react-intl";
import authApi from "../../services/api/auth";
import {Link} from "react-router-dom";
import Urls from "../../assets/js/Urls";
import {BlockError} from "../utils/Blocks";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appClearAuthState, getTries} from "../../store/slice/app";
import TrialsInfo from "./TrialsInfo";
import SimpleForm from "../misc/SimpleForm";
import {LoginArgs} from "../../types";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import {FormControl, InputAdornment, TextField, Typography} from "@mui/material";
import Button from '@mui/material/Button';

const Login = () => {
    const dispatch = useAppDispatch();
    const [login, loginResult] = authApi.useLazyLoginQuery();
    const tries = useAppSelector(getTries);

    useEffect(() => {
        dispatch(appClearAuthState());
    }, [true]);

    return <AppPublic page={"login"}>
        <SimpleForm onSubmit={(form) => login(form as LoginArgs)}>
           <AuthAppTitle>Connexion</AuthAppTitle>
           <FormControl
               fullWidth={true}
               sx={{mb: 2}}
           >
               <TextField
                   name="email"
                   type="email"
                   placeholder="E-mail"
                   size={"small"}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <MailOutlinedIcon />
                           </InputAdornment>
                       ),
                   }}
               />
           </FormControl>
           <FormControl
               fullWidth={true}
           >
               <TextField
                   name="password"
                   type="password"
                   placeholder="Mot de passe"
                   size={"small"}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <LockOutlinedIcon/>
                           </InputAdornment>
                       ),
                   }}
                   helperText={<Typography className={"text-xs"} align={"right"}><Link to={Urls.forgot}><FormattedMessage id="loginForgotPassword"/></Link></Typography>}
               />
           </FormControl>
           <Button
               variant={"contained"}
               type={"submit"}
               endIcon={<ArrowForwardRoundedIcon/>}
           ><FormattedMessage id="loginConnect"/></Button>
        </SimpleForm>
        {
            tries !== null && <TrialsInfo tries={tries} />
        }
        <BlockError data={loginResult} />

        <Typography
            align={"center"}
            className={"text-xs"}
            sx={{marginTop:"16px"}}
        >
            <FormattedMessage id="loginNoAccount"/><br/>
            <Link to={Urls.register} className={"text-md"}>
                <FormattedMessage id="register"/>
            </Link>
        </Typography>
    </AppPublic>
}

export default Login;