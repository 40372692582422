import React, {useEffect} from "react";
import {ChartConfiguration} from "chart.js";
import Chart from "chart.js/auto";

type AllChartType = ChartConfiguration<"line" | "bar" | "doughnut">

const CustomChart:React.FC<{
    config: AllChartType,
    width?: number,
    height?: number,
}> = ({config, width, height}) => {
    const identifier:string = `chart-${config.type}-${Math.random() * Date.now()}`;

    useEffect(() => {
        const cht = new Chart(identifier, config);

        return () => {
            cht.destroy();
        };
    }, [JSON.stringify(config)]);

    return <>
        <canvas id={identifier} width={width || 1200} height={height || 400} />
    </>;
};

export default CustomChart;