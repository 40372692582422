import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import ApiUrls, {getCustomHeaders} from "../../assets/js/ApiUrls";
import {Register} from "../../types";

const registersApi = createApi({
    reducerPath: "api.registers",
    baseQuery: fetchBaseQuery({
        prepareHeaders:(headers, store) => getCustomHeaders(headers, true),
        baseUrl: process.env.REACT_APP_API_DOMAIN,
    }),
    tagTypes:["currentRegister"],
    endpoints: (build) => ({
        getRegister: build.query<Register, string>({
            query: (params) => ApiUrls.registers.register(params),
            providesTags: ["currentRegister"],
        }),
    })
});

export default registersApi;